import { axiosGlobal } from "./config";

const getNotificationList = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/customer/notifikasi`, { params: params }
  )
  return response
}
const getNotificationRead = async (id) => {
  const { data: response } = await axiosGlobal.get(
    `/customer/notifikasi/${id}`
  )
  return response.data
}
const getNotificationReadAll = async () => {
  const { data: response } = await axiosGlobal.get(
    `/customer/notifikasi/read-all`
  )
  return response.data
}

export {
  getNotificationList,
  getNotificationRead,
  getNotificationReadAll
}