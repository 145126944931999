export const ImageFillIcon = ({ className }) => {
  return (
    <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_915_88399)">
        <path d="M40 10H8V38L26.584 19.412C26.9591 19.0371 27.4677 18.8264 27.998 18.8264C28.5283 18.8264 29.0369 19.0371 29.412 19.412L40 30.02V10ZM4 7.986C4.00366 7.46076 4.2138 6.95803 4.58503 6.58644C4.95625 6.21484 5.45876 6.00419 5.984 6H42.016C43.112 6 44 6.89 44 7.986V40.014C43.9963 40.5392 43.7862 41.042 43.415 41.4136C43.0438 41.7852 42.5412 41.9958 42.016 42H5.984C5.45763 41.9995 4.95299 41.79 4.58098 41.4176C4.20896 41.0452 4 40.5404 4 40.014V7.986ZM16 22C14.9391 22 13.9217 21.5786 13.1716 20.8284C12.4214 20.0783 12 19.0609 12 18C12 16.9391 12.4214 15.9217 13.1716 15.1716C13.9217 14.4214 14.9391 14 16 14C17.0609 14 18.0783 14.4214 18.8284 15.1716C19.5786 15.9217 20 16.9391 20 18C20 19.0609 19.5786 20.0783 18.8284 20.8284C18.0783 21.5786 17.0609 22 16 22Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_915_88399">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}