export const SecurePaymentLineIcon = ({ className }) => {
  return (
    <svg className={className} width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8391 2.83334L21.3538 5.49417C21.8407 5.64638 22.1724 6.09746 22.1724 6.60773V8.66668H24.5058C25.1501 8.66668 25.6724 9.18902 25.6724 9.83334L25.6724 19.1667C25.6724 19.811 25.1501 20.3333 24.5058 20.3333L20.749 20.3346C20.2979 20.9293 19.7489 21.4554 19.1158 21.8871L12.8391 26.1667L6.56238 21.8871C4.64995 20.5831 3.50574 18.4181 3.50574 16.1035L3.50574 6.60773C3.50574 6.09746 3.83736 5.64638 4.32442 5.49417L12.8391 2.83334ZM12.8391 5.27634L5.83907 7.46501L5.83907 16.1035C5.83907 17.5559 6.51478 18.9198 7.65722 19.7999L7.87684 19.9592L12.8391 23.3422L17.2514 20.3333H11.6724C11.0281 20.3333 10.5057 19.811 10.5057 19.1667L10.5057 9.83334C10.5057 9.18902 11.0281 8.66668 11.6724 8.66668L19.8391 8.66668V7.46501L12.8391 5.27634ZM12.8391 14.5V18H23.3391L23.3391 14.5H12.8391ZM12.8391 12.1667H23.3391V11H12.8391V12.1667Z" fill="url(#paint0_linear_737_75229)" />
      <defs>
        <linearGradient id="paint0_linear_737_75229" x1="14.5891" y1="2.83334" x2="14.5891" y2="26.1667" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEF6F1" />
          <stop offset="1" stopColor="#F9D3BA" />
        </linearGradient>
      </defs>
    </svg>

  )
}