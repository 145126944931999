export function numberToWord(num) {
  const numberWords = {
    1: 'satu',
    2: 'dua',
    3: 'tiga',
    4: 'empat',
    5: 'lima',
    6: 'enam',
    7: 'tujuh',
    8: 'delapan',
    9: 'sembilan',
    10: 'sepuluh'
  };

  return numberWords[num] || 'tidak ada';
}