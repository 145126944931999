import { LoadingIcon } from "../../assets/icons"
import Container from "../../components/atoms/Container"

const LoadingPage = () => {
  return (
    <Container className="h-[calc(100vh-66px)] lg:h-[calc(100vh-139px)] py-20 flex items-center justify-center">
      <LoadingIcon className="h-10 w-10 text-color-primary-500" />
    </Container>
  )
}

export default LoadingPage