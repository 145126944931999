import { axiosGlobal } from "./config";

const getAuctionUnitType = async () => {
  const { data: response } = await axiosGlobal.get(
    `/tipe-produk`
  )
  return response.data
}
const getAuctionBranch = async () => {
  const { data: response } = await axiosGlobal.get(
    `/branch-location`
  )
  return response.data
}

const getBanners = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/banner`, { params }
  )
  return response.data
}

const getProcedures = async () => {
  const { data: response } = await axiosGlobal.get(
    `/kategori-prosedur`, { params: { limit: 10, page: 1 } }
  )
  return response.data
}
const getPoolLocations = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/pool-location`, { params }
  )
  return response.data
}
const getAboutUs = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/tentang-kami`, { params }
  )
  return response.data
}
const getCountProfile = async () => {
  const { data: response } = await axiosGlobal.get(
    `/customer/pengaturan/profile/get-counter`,
  )
  return response.data
}
const postContactUs = async (body) => {
  const { data: response } = await axiosGlobal.post(
    `/hubungi-kami`, body
  )
  return response
}

export {
  getAuctionUnitType,
  getBanners,
  getAuctionBranch,
  getProcedures,
  getPoolLocations,
  getAboutUs,
  getCountProfile,
  postContactUs
}