export const YoutubeIcon = ({ className }) => {
  return (
    <svg className={className} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1457_17130)">
        <path d="M28.724 9.16398C29.3333 11.54 29.3333 16.5 29.3333 16.5C29.3333 16.5 29.3333 21.46 28.724 23.836C28.3853 25.1493 27.3947 26.1826 26.14 26.532C23.8613 27.1666 16 27.1666 16 27.1666C16 27.1666 8.14266 27.1666 5.86 26.532C4.6 26.1773 3.61066 25.1453 3.276 23.836C2.66666 21.46 2.66666 16.5 2.66666 16.5C2.66666 16.5 2.66666 11.54 3.276 9.16398C3.61466 7.85065 4.60533 6.81731 5.86 6.46798C8.14266 5.83331 16 5.83331 16 5.83331C16 5.83331 23.8613 5.83331 26.14 6.46798C27.4 6.82265 28.3893 7.85465 28.724 9.16398ZM13.3333 21.1666L21.3333 16.5L13.3333 11.8333L13.3333 21.1666Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1457_17130">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>

  )
}