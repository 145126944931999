export const PriceTagIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1820_32614)">
      <path
        d="M9.08353 1.75L17.3327 2.92917L18.511 11.1792L10.851 18.8392C10.6948 18.9954 10.4828 19.0832 10.2619 19.0832C10.0409 19.0832 9.82897 18.9954 9.6727 18.8392L1.4227 10.5892C1.26647 10.4329 1.17871 10.221 1.17871 10C1.17871 9.77903 1.26647 9.56711 1.4227 9.41083L9.08353 1.75ZM11.4402 8.82167C11.595 8.97641 11.7788 9.09914 11.981 9.18287C12.1832 9.26659 12.4 9.30967 12.6188 9.30963C12.8377 9.30959 13.0544 9.26644 13.2566 9.18264C13.4588 9.09885 13.6425 8.97605 13.7973 8.82125C13.952 8.66645 14.0748 8.4827 14.1585 8.28047C14.2422 8.07824 14.2853 7.8615 14.2852 7.64262C14.2852 7.42375 14.2421 7.20702 14.1583 7.00482C14.0745 6.80262 13.9517 6.61891 13.7969 6.46417C13.6421 6.30943 13.4583 6.18669 13.2561 6.10296C13.0539 6.01924 12.8371 5.97617 12.6182 5.97621C12.1762 5.97629 11.7523 6.15196 11.4398 6.46458C11.1273 6.77721 10.9517 7.20117 10.9518 7.64321C10.9519 8.08525 11.1276 8.50915 11.4402 8.82167Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1820_32614">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);