import { countData } from "./countDataType";

const initialCountData = {
  user_id: 0,
  notification_total: 0,
  nipl_total: 0,
  proxy_total: 0
}

export const countDataReducer = (state = { ...initialCountData }, action) => {
  switch (action.type) {
    case countData.SET_COUNT_DATA:
      return action.payload;
    default:
      return state;
  }
};
