import React from 'react'

export const AlertLineIcon = ({ className }) => (
  <svg
    width="25"
    height="24"
    className={className}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3661 2.99996L22.8921 19.5C22.9799 19.652 23.0261 19.8244 23.0261 20C23.0261 20.1755 22.9799 20.3479 22.8921 20.4999C22.8043 20.652 22.6781 20.7782 22.5261 20.866C22.3741 20.9537 22.2016 21 22.0261 21H2.9741C2.79856 21 2.62612 20.9537 2.4741 20.866C2.32209 20.7782 2.19585 20.652 2.10809 20.4999C2.02032 20.3479 1.97412 20.1755 1.97412 20C1.97412 19.8244 2.02033 19.652 2.1081 19.5L11.6341 2.99996C11.7219 2.84795 11.8481 2.72172 12.0001 2.63396C12.1521 2.5462 12.3246 2.5 12.5001 2.5C12.6756 2.5 12.8481 2.5462 13.0001 2.63396C13.1521 2.72172 13.2783 2.84795 13.3661 2.99996ZM4.7061 19H20.2941L12.5001 5.49996L4.7061 19ZM11.5001 16H13.5001V18H11.5001V16ZM11.5001 8.99996H13.5001V14H11.5001V8.99996Z"
      fill="currentColor"
    />
  </svg>
);
