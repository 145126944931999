export const GasStationIcon = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2013_21787)">
        <path d="M9.33331 12.6667H9.99998V14L1.33331 14L1.33331 12.6667H1.99998L1.99998 2.66667C1.99998 2.48986 2.07022 2.32029 2.19524 2.19526C2.32027 2.07024 2.48984 2 2.66665 2L8.66665 2C8.84346 2 9.01303 2.07024 9.13805 2.19526C9.26308 2.32029 9.33331 2.48986 9.33331 2.66667V8H10.6666C11.0203 8 11.3594 8.14048 11.6095 8.39052C11.8595 8.64057 12 8.97971 12 9.33333V12C12 12.1768 12.0702 12.3464 12.1952 12.4714C12.3203 12.5964 12.4898 12.6667 12.6666 12.6667C12.8435 12.6667 13.013 12.5964 13.1381 12.4714C13.2631 12.3464 13.3333 12.1768 13.3333 12L13.3333 7.33333L12 7.33333C11.8232 7.33333 11.6536 7.2631 11.5286 7.13807C11.4036 7.01305 11.3333 6.84348 11.3333 6.66667V4.276L10.2286 3.17133L11.1713 2.22867L14.4713 5.52867C14.5333 5.59049 14.5825 5.66396 14.616 5.74484C14.6495 5.82573 14.6667 5.91244 14.6666 6L14.6666 12C14.6666 12.5304 14.4559 13.0391 14.0809 13.4142C13.7058 13.7893 13.1971 14 12.6666 14C12.1362 14 11.6275 13.7893 11.2524 13.4142C10.8774 13.0391 10.6666 12.5304 10.6666 12V9.33333H9.33331V12.6667ZM3.33331 12.6667H7.99998V8.66667H3.33331L3.33331 12.6667ZM3.33331 3.33333L3.33331 7.33333L7.99998 7.33333L7.99998 3.33333L3.33331 3.33333Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_2013_21787">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}