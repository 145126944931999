import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const protectedPath = [
    '/setting',
    '/live-auction',
    '/time-auction',
    '/offline-auction',
    '/profile/setting',
    '/profile/notification',
    '/profile/favorite',
    '/profile/nipl',
    '/profile/transaction',
    '/profile/proxy-bid',
  ]
  const { pathname } = useLocation();
  const isAuth = useSelector(state => state.logged)

  if (!isAuth && protectedPath.find((path) => pathname.includes(path))) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default PrivateRoute;
