import CustomModal from "../atoms/CustomModal"
import { loginModalHide } from "../../redux/loginModal.js/loginModalAction"
import { store } from "../../redux/store"
import LoginContent from "../pages/login"

const ModalSignIn = ({ openModal }) => {
  const handleCloseModal = () => {
    store.dispatch(loginModalHide())
  }
  return (
    <CustomModal openModal={openModal} handleCloseModal={handleCloseModal}>
      <LoginContent />
    </CustomModal>
  )
}

export default ModalSignIn