export const RunFillIcon = ({ className }) => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4682 10.7548L9.33332 11.5319L9.33332 15.6667H6.99999L6.99999 9.89167H7.01751L13.164 7.65455C13.4486 7.54498 13.7576 7.49022 14.0756 7.50093C15.3722 7.53282 16.5109 8.38279 16.907 9.62375C17.1243 10.305 17.3227 10.7648 17.5018 11.0029C18.5661 12.4181 20.2595 13.3333 22.1667 13.3333V15.6667C19.6295 15.6667 17.3627 14.5097 15.8648 12.6947L15.1865 16.5413L17.5 18.7817L17.5 27.3333H15.1667L15.1667 20.3499L12.7758 18.0316L11.6702 23.0462L3.62762 21.6281L4.0328 19.3301L9.77751 20.3431L11.4682 10.7548ZM15.75 6.91667C14.4613 6.91667 13.4167 5.872 13.4167 4.58333C13.4167 3.29467 14.4613 2.25 15.75 2.25C17.0387 2.25 18.0833 3.29467 18.0833 4.58333C18.0833 5.872 17.0387 6.91667 15.75 6.91667Z" fill="url(#paint0_linear_737_75236)" />
      <defs>
        <linearGradient id="paint0_linear_737_75236" x1="12.8972" y1="2.25" x2="12.8972" y2="27.3333" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEF6F1" />
          <stop offset="1" stopColor="#F9D3BA" />
        </linearGradient>
      </defs>
    </svg>

  )
}