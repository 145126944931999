import { useEffect } from "react";
import { createPortal } from "react-dom";
import { XIcon } from "../../assets/icons";
import { Typography } from "./Typography";

const CustomModal = ({
  openModal,
  setOpenModal,
  handleCloseModal,
  title,
  className,
  children,
  hideClose
}) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openModal]);

  return (
    <>
      {openModal &&
        createPortal((
          // <div className="absolute z-10 top-0 h-screen w-screen bg-black opacity-50 flex items-center justify-center overflow-hidden ">
          <div className='fixed inset-0 z-[1000] px-4 bg-[rgba(0,0,0,0.50)] overflow-hidden flex justify-center items-center'>
            <div className={`bg-white relative min-w-full lg:min-w-[430px] rounded-lg p-6 ${className}`} >
              {
                title ?
                  <div className="flex justify-between pb-4 gap-[10px] items-center ">
                    <Typography text={title} size="xl" color="base" weight="semibold" className="" />
                    <button className="" onClick={handleCloseModal ?  () => handleCloseModal() : () => setOpenModal(false)}>
                      <XIcon className="text-Base-Icon" />
                    </button>
                  </div>
                  :
                  <>
                    {
                      !hideClose &&
                      <button className="absolute right-3 top-3" onClick={handleCloseModal ?  () => handleCloseModal() : () => setOpenModal(false)}>
                        <XIcon className="text-Base-Icon" />
                      </button>
                    }
                  </>
              }
              {children}
            </div>
          </div>
        ), document.body
        )
      }
    </>
  )
}

export default CustomModal;