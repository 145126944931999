export const HeartIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9999 3.8291C9.0799 2.99076 7.90657 2.53076 6.6699 2.53076C5.3549 2.53076 4.1099 3.04993 3.15907 3.99576C1.19824 5.96493 1.19907 9.04493 3.16074 11.0058L9.27074 17.1158C9.4124 17.3649 9.68574 17.5258 9.9999 17.5258C10.2682 17.5258 10.5074 17.3899 10.6599 17.1849L16.8391 11.0058C18.8007 9.0441 18.8007 5.96493 16.8374 3.99243C15.8899 3.04993 14.6449 2.53076 13.3299 2.53076C12.0941 2.53076 10.9199 2.99076 9.9999 3.8291ZM15.6591 5.17076C16.9616 6.47993 16.9624 8.52493 15.6607 9.82743L9.9999 15.4883L4.33907 9.82743C3.0374 8.52493 3.03824 6.47993 4.3374 5.1741C4.97074 4.5441 5.79907 4.19743 6.6699 4.19743C7.54074 4.19743 8.36574 4.5441 8.99407 5.17243L9.41074 5.5891C9.73657 5.91493 10.2632 5.91493 10.5891 5.5891L11.0057 5.17243C12.2657 3.91493 14.4007 3.91826 15.6591 5.17076Z"
      fill="currentColor"
    />
  </svg>
);

