import React from 'react'

export const ChevronUpIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70703 14.707L12 10.414L16.293 14.707L17.707 13.293L12 7.58603L6.29303 13.293L7.70703 14.707Z"
        fill="currentColor"
      />
    </svg>
  );
}
