import CryptoJS from 'crypto-js';

const secretKey = window.REACT_APP_PARAMS
// const secretKey = 'uma'

export function encrypt(word) {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), secretKey).toString()
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData.replace(/\+/g, 'qT23LsT')?.replace(/\//g, 'k92JdfkT')?.replace(/=/g, 'Edxf423jR')
}

//解密方法aes
export function decrypt(word) {
  let wordConvert = word.replace(/qT23LsT/g, '+')?.replace(/k92JdfkT/g, '/')?.replace(/Edxf423jR/g, '=')
  let decData = CryptoJS.enc.Base64.parse(wordConvert).toString(CryptoJS.enc.Utf8)
  let bytes = CryptoJS.AES.decrypt(decData, secretKey).toString(CryptoJS.enc.Utf8)
  return JSON.parse(bytes)
}