export function useQueryParams(search) {
  const queryParams = new URLSearchParams(search);

  // Convert URLSearchParams to an object
  const paramsObject = {};
  queryParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
}