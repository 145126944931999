export const LinkedinIcon = ({ className }) => {
  return (
    <svg className={className} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1457_17140)">
        <path d="M24.4467 24.952H20.8933V19.3827C20.8933 18.0547 20.8667 16.3453 19.04 16.3453C17.188 16.3453 16.9053 17.7907 16.9053 19.2853V24.952H13.3507V13.5H16.764V15.06H16.8107C17.288 14.1613 18.448 13.2107 20.1813 13.2107C23.7813 13.2107 24.448 15.5813 24.448 18.6653L24.448 24.952H24.4467ZM9.33733 11.9333C9.06607 11.9337 8.79741 11.8805 8.54675 11.7768C8.29609 11.6731 8.06836 11.521 7.87661 11.3291C7.68486 11.1372 7.53287 10.9094 7.42935 10.6587C7.32582 10.408 7.27281 10.1393 7.27333 9.868C7.2736 9.45978 7.39491 9.0608 7.62192 8.72153C7.84894 8.38225 8.17146 8.11791 8.54871 7.96194C8.92595 7.80596 9.34098 7.76536 9.74131 7.84525C10.1416 7.92515 10.5093 8.12197 10.7977 8.41081C11.0862 8.69965 11.2826 9.06755 11.3619 9.46798C11.4413 9.8684 11.4002 10.2834 11.2437 10.6604C11.0872 11.0375 10.8225 11.3597 10.4829 11.5862C10.1434 11.8128 9.74422 11.9336 9.336 11.9333H9.33733ZM11.1187 24.952H7.55467L7.55467 13.5H11.12L11.12 24.952H11.1187ZM26.2267 4.5L5.772 4.5C4.79067 4.5 4 5.27333 4 6.22933L4 26.7707C4 27.7267 4.792 28.5 5.77067 28.5L26.2213 28.5C27.2 28.5 28 27.7267 28 26.7707L28 6.22933C28 5.27333 27.2 4.5 26.2213 4.5H26.2253H26.2267Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1457_17140">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>

  )
}