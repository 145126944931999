export const FacebookIcon = ({ className }) => {
  return (
    <svg className={className} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1457_17125)">
        <path d="M16 3.16669C8.636 3.16669 2.66667 9.13602 2.66667 16.5C2.66667 23.1547 7.54267 28.6707 13.9173 29.672L13.9173 20.3534H10.5307V16.5H13.9173V13.5627C13.9173 10.2214 15.9067 8.37602 18.9533 8.37602C20.412 8.37602 21.9373 8.63602 21.9373 8.63602V11.916H20.2573C18.6 11.916 18.084 12.944 18.084 13.9987V16.5H21.7813L21.1907 20.3534H18.084L18.084 29.672C24.4573 28.672 29.3333 23.1534 29.3333 16.5C29.3333 9.13602 23.364 3.16669 16 3.16669Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1457_17125">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}