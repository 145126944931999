import toast from "react-hot-toast";

export const toastSuccess = (message) => {
  toast.success(message || 'success', {
    duration: 4000, // Optional, duration of the toast in milliseconds
    position: 'top-center', // Optional, location of the toast on the screen
  });
}

export const toastError = (message) => {
  toast.error(message || 'failed', {
    duration: 4000, // Optional, duration of the toast in milliseconds
    position: 'top-center', // Optional, location of the toast on the screen
  });
}