export const buttonSize = {
  xxs: "p-2 text-xs",
  xs: "px-3 py-2 text-xs",
  sm: "px-3.5 py-2.5 text-sm",
  md: "px-4 py-3 text-md",
  lg: "px-4.5 py-3.5 text-lg ",
};

export const buttonStyle = {
  "primary-primary":
    "bg-Button-primary-Primary text-Button-primary-Text hover:bg-Button-primary-Hover",
  "secondary-primary":
    "bg-Button-primary-Secondary text-Button-primary-Primary hover:bg-Button-primary-Hover hover:text-Button-primary-Text",
  "outline-primary":
    "border border-Button-primary-Primary text-Button-primary-Primary hover:bg-Button-primary-Hover hover:text-Button-primary-Text",

  "primary-secondary":
    "bg-Button-secondary-Primary text-Button-secondary-Text hover:bg-Button-secondary-Hover",
  "secondary-secondary":
    "bg-Button-secondary-Secondary text-Button-secondary-Primary hover:bg-Button-secondary-Hover hover:text-Button-secondary-Text",
  "outline-secondary":
    "border border-Button-secondary-Primary text-Button-secondary-Primary hover:bg-Button-secondary-Hover hover:text-Button-secondary-Text",

  "primary-success":
    "bg-Button-success-Primary text-Button-success-Text hover:bg-Button-success-Hover",
  "secondary-success":
    "bg-Button-success-Secondary text-Button-success-Primary hover:bg-Button-success-Hover hover:text-Button-success-Text",
  "outline-success":
    "border border-Button-success-Primary text-Button-success-Primary hover:bg-Button-success-Hover hover:text-Button-success-Text",

  "primary-danger":
    "bg-Button-danger-Primary text-Button-danger-Text hover:bg-Button-danger-Hover",
  "secondary-danger":
    "bg-Button-danger-Secondary text-white hover:bg-Button-danger-Hover hover:text-Button-danger-Text",
  "outline-danger":
    "border border-Button-danger-Primary text-white hover:bg-Button-danger-Hover hover:text-Button-danger-Text",

  "primary-gray":
    "bg-Button-gray-Primary text-white hover:bg-Button-gray-Hover",
  "secondary-gray":
    "bg-Button-gray-Secondary text-Button-gray-Primary hover:bg-Button-gray-Hover hover:text-white",
  "outline-gray":
    "border border-Button-gray-Primary text-Button-gray-Primary hover:bg-Button-gray-Hover hover:text-white",

  "outline-light":
    "border border-Base-Inline text-Base-Primary hover:bg-Base-Inline",
  "transparent-transparent": "bg-transparent text-color-neutral-500",
  "primary-100":
    "bg-color-primary-100 text-Text-primary hover:bg-Button-primary-Hover hover:text-white",
  "primary-neutral": "bg-color-neutral-500 text-color-neutral-25",
};

export const disabledStyle = {
  primary: "cursor-not-allowed bg-color-neutral-300 text-white",
  secondary:
    "cursor-not-allowed border border-color-neutral-400 bg-color-neutral-100 text-color-neutral-400",
  outline:
    "cursor-not-allowed border border-color-neutral-400 bg-color-neutral-100 text-color-neutral-400",
};
