import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonCustom = ({
    height,
    width,
    count,
    circle,
    className
}) => <Skeleton width={width} circle={circle} height={height} count={count} className={className} highlightColor="#F8FAFC" baseColor="#CDD5DF" />

export default SkeletonCustom