import { loginModalType } from "./loginModalType";

export const loginModalReducer = (state = false, action) => {
    switch (action.type) {
        case loginModalType.LOGIN_MODAL_SHOW:
            return true;
        case loginModalType.LOGIN_MODAL_HIDE:
            return false;
        default:
            return state;
    }
}