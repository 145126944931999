import { productType } from "./productType";

export const productTypeReducer = (state = [], action) => {
  switch (action.type) {
    case productType.SET_PRODUCT_TYPE:
      return action.payload;
    default:
      return state;
  }
};
