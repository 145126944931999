import { useMemo } from "react";
import { buttonSize, buttonStyle, disabledStyle } from "./ButtonStyle";

const Button = ({
  children,
  type = "button",
  intent = "primary",
  color = "primary",
  size = 'sm',
  disabled,
  className,
  onClick = () => { }
}) => {

  const finalButtonClassName = useMemo(() => {
    const defaulButton = 'rounded-lg font-medium';
    let btnStyle = '';
    if (disabled) {
      btnStyle = disabledStyle[intent]
    } else {
      btnStyle = buttonStyle[`${intent}-${color}`]
    }
    return `${defaulButton} ${btnStyle} ${buttonSize[size]}`
  }, [disabled, intent, color, size])

  const handleClick = () => {
    onClick()
  }

  return (
    <button
      // id={id ?? buttonId}
      // ref={ref}
      type={type ?? "button"}
      onClick={handleClick}
      // className={finalButtonClassName}
      disabled={disabled}
      // {...rest}
      className={`${finalButtonClassName} ${className}`}
    >
      {children}
    </button>
  )
}

export default Button