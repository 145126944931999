import { useCallback, useEffect, useRef } from "react"

const HeaderDropdown = ({ open, setOpen= () => {}, button, children, position = 'left' }) => {
  const dropdownRef = useRef(null);
  const handleClick = useCallback((val) => setOpen(val), [setOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClick(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClick]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button className="block" onClick={() => handleClick(true)}>
        {button}
      </button>
      {
        open && (
          <div className={`absolute mt-3.5 rounded-md bg-white border border-color-neutral-200 ${positionValues[position]}`}>
            {children}
          </div>
        )
      }
    </div>
  )
}

export default HeaderDropdown

const positionValues = {
  'left': 'left-0',
  'right': 'right-0',
  'minus-right': '-right-20',
  'center': 'inset-x-0',
}