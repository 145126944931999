import { useMemo, useState } from 'react'
import { IcEyeOffIcon, IcEyeOnIcon } from '../../assets/icons';
import { forwardRef } from 'react';

const Input = forwardRef(({
  maxLength = 255,
  disabled,
  placeholder,
  className,
  type = 'text',
  name,
  onChange,
  errorMessage = 'asdf',
  ...rest
}, ref) => {
  const [iconPassword, setIconPassword] = useState(false);
  const containerClassNames = useMemo(() => {
    return (
      `relative w-full flex items-center gap-2 rounded-md text-sm font-medium outline-none
        border border-color-neutral-200
       focus:ring-1 focus:ring-Input-default-main
       
       ${disabled ? "cursor-not-allowed text-color-neutral-400 bg-color-neutral-200" : "bg-white text-color-neutral-700 cursor-pointer"}
       ${errorMessage ? "" : ""}
       px-4 py-2.5 transition duration-300 ease-in-out`
    ) + (className ? ` ${className}` : "");
  }, [disabled, className, errorMessage]);
  return (
    <div className='relative'>
      <input
        maxLength={maxLength}
        {...rest}
        placeholder={!disabled ? placeholder : ""}
        type={!iconPassword ? type : "text"}
        autoComplete={type === "password" ? "new-password" : "off"}
        className={containerClassNames}
        disabled={disabled}
        onChange={onChange}
        name={name}
        // onKeyDown={(e) => {
        //   e.stopPropagation();
        // }}
        ref={ref}
      />
      {type === "password" && (
        <span className="absolute inset-y-0 right-0 flex items-center  pr-3">
          <button
            className="relative"
            data-testid="toggle-type"
            onClick={() => setIconPassword(prev => !prev)}
            type="button"
          >
            {!iconPassword ? (
              <IcEyeOffIcon className="h-5 w-5 text-whitealpha-600" />
            ) : (
              <IcEyeOnIcon className="h-5 w-5 text-whitealpha-600" />
            )}
          </button>
        </span>
      )}
    </div>
  )
})

export default Input