export const CheckFIllIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_915_88366)">
        <path d="M8.33333 12.6433L15.9933 4.98242L17.1725 6.16076L8.33333 14.9999L3.03 9.69659L4.20833 8.51826L8.33333 12.6433Z" fill="#00AF54" />
      </g>
      <defs>
        <clipPath id="clip0_915_88366">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}