import SkeletonCustom from "../../atoms/SkeletonCustom"

const NotificationItemSkeleton = () => {
  return (
    <div className={`py-[10px] px-5 flex flex-col cursor-pointer`} >
      <SkeletonCustom width="150px" />
      <SkeletonCustom width="90px"/>
      <SkeletonCustom count={2}/>
    </div>
  )
}

export default NotificationItemSkeleton