import { axiosGlobal } from "./config";

const postChangePassword = async (body) => {
  const { data: response } = await axiosGlobal.post(
    `/customer/pengaturan/reset-password`, body
  )
  return response.data
}
const putChangeProfile = async (body) => {
  const { data: response } = await axiosGlobal.put(
    `/customer/pengaturan/profile`, body
  )
  return response.data
}
const putChangeProfilePicture = async (body) => {
  const { data: response } = await axiosGlobal.put(
    `/customer/pengaturan/profile/profile-image`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      },
    }
  )
  return response
}
const putChangeIdentityPicture = async (body) => {
  const { data: response } = await axiosGlobal.put(
    `/customer/pengaturan/profile/identity-file`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      },
    }
  )
  return response.data
}
const putChangeBank = async (body) => {
  const { data: response } = await axiosGlobal.put(
    `/customer/pengaturan/profile`, body
  )
  return response.data
}

const getProfileDetail = async () => {
  const { data: response } = await axiosGlobal.get(
    `/customer/pengaturan/profile`,
  )
  return response.data
}
const getListCity = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/customer/city`, { params: params }
  )
  return response.data
}
const getListBank = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/customer/bank`, { params: params }
  )
  return response.data
}

export {
  postChangePassword,
  putChangeProfile,
  putChangeProfilePicture,
  putChangeIdentityPicture,
  putChangeBank,
  getListCity,
  getListBank,
  getProfileDetail
}