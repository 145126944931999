import { loggedType } from './loggedType';

export const loggedReducer = (state = false, action) => {
    switch (action.type) {
        case loggedType.LOGGED_IN:
            return true;
        case loggedType.LOGGED_OUT:
            return false;
        default:
            return state;
    }
}