import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MSLLogo, PremiumUserHeader } from "../../assets/images";
import Button from "../atoms/Button";
import Container from "../atoms/Container";
import HeaderCategory from "../molecules/HeaderCategory";
import HeaderNotification from "../molecules/HeaderNotification";
import HeaderProfile from "../molecules/HeaderProfile";
import { ApplicationIcon, ArrowDropdownFillIcon, MenuFillIcon, MobileIcon, PremiumUserIcon, SearchLineIcon, XIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { loginModalShow } from "../../redux/loginModal.js/loginModalAction";
import { useEffect, useState } from "react";
import { Typography } from "../atoms/Typography";
import { useQuery } from "react-query";
import { getProfileDetail } from "../../api/profileSetting";
import { encrypt } from "../../utils";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isLogged = useSelector(state => state.logged)
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false)
  const [keyword, setKeyword] = useState('')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      navigate(`/schedule-catalogue?search=${keyword}`);
      setIsOpen(false)
    }
  };

  const handleClick = () => {
    navigate('/')
  }
  const handleClickMasuk = () => {
    store.dispatch(loginModalShow())
  }

  const handleClickMasukMobile = () => {
    navigate('/login', { state: { previous_path: currentPath, isMobile: true } })
  }
  useQuery({
    queryKey: 'getProfileDetailHeader',
    queryFn: () => getProfileDetail(),
    onSuccess: (data) => {
      const dataBank = {
        dentity_address: data.identity_address,
        current_address: data.current_address,
        bank_id: data.bank_id,
        rekening_number: data.rekening_number,
        rekening_owner: data.rekening_owner,
        bank_office: data.bank_office,
      }
      const dataUser = {
        profile_image: data.profile_image,
        created_at: data.created_at,
        company_name: data.company_name,
        full_name: data.full_name,
        seller_type: data.seller_type
      }
      localStorage.setItem('data_bank', JSON.stringify(dataBank))
      localStorage.setItem('is_prem', data.is_prem)
      localStorage.setItem('data_user', JSON.stringify(dataUser))
    },
    staleTime: Infinity,
    retry: 1,
    enabled: isLogged
  })
  return (
    <>
      <header className="bg-white hidden sticky lg:block top-0 w-screen shadow-md z-[3] header" id="header">
        <Container className={'justify-between flex items-center py-4 space-x-10'}>
          <div className="space-x-10 flex justify-between items-center flex-row flex-grow">
            <img alt="MSL Kopkara Auction" className="w-[118.55px]" src={MSLLogo} onClick={handleClick} />
            <HeaderCategory />
            <div className="w-full h-10 rounded-full px-4 py-3 border border-color-neutral-200 flex items-center space-x-[10px]">
              <SearchLineIcon />
              <input
                type="text"
                placeholder="Search Car"
                className="w-full border-transparent ring-0 focus:border-transparent focus:ring-0 focus:outline-none"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="flex space-x-4">
            {
              isLogged ? (
                <div className="flex items-center">
                  <HeaderNotification position="right" />
                  <div className="mx-5 border-r border-color-neutral-200 h-full" >
                  </div>
                  <HeaderProfile />
                </div>
              ) : (
                <>
                  <Button
                    intent="outline"
                    color="primary"
                    size="sm"
                    onClick={() => navigate('/register')}
                  >
                    Daftar
                  </Button>
                  <Button
                    intent="primary"
                    color="primary"
                    size="sm"
                    onClick={handleClickMasuk}
                  >
                    Masuk
                  </Button>

                </>
              )
            }
          </div >
        </Container >
        <Container className={'border-t border-color-neutral-200 flex py-4 justify-between'}>
          <div className="flex space-x-10">
            {
              bottomList.map((item, index) => (
                <NavLink
                  key={`navling-header-${index + 1}`}
                  to={item.path}
                  className="text-color-neutral-500 hover:text-color-primary-500 text-base"
                >
                  {item.label}
                </NavLink>
              ))
            }
            <NavLink to="/premium-auction">
              <PremiumUserIcon />
            </NavLink>
          </div>
          <button className="flex items-center space-x-1">
            <MobileIcon />
            <Typography text="Download Aplikasi" color="base" weight="semibold" size="base" />
          </button>
        </Container>
      </header >
      <header className="bg-white flex justify-between py-3 px-4 sticky lg:hidden top-0 w-screen shadow-md z-[3]">
        <div className="flex items-center space-x-4">
          <button type="button" onClick={() => setIsOpen(true)}>
            <MenuFillIcon />
          </button>
          <img alt="MSL Kopkara Auction" className="h-[37px] w-[77px] cursor-pointer" src={MSLLogo} onClick={handleClick} />
        </div>
        <div className="flex items-end space-x-4">
          {
            isLogged ? (
              <>
                <HeaderNotification position="minus-right" />
                <div className="mx-5 border-r border-color-neutral-200 h-full" />
                <HeaderProfile />
              </>
            ) : (
              <>
                <Button
                  intent="outline"
                  color="primary"
                  size="sm"
                  onClick={() => navigate('/register')}
                >
                  Daftar
                </Button>
                <Button
                  intent="primary"
                  color="primary"
                  size="sm"
                  onClick={handleClickMasukMobile}
                >
                  Masuk
                </Button>

              </>
            )
          }
        </div>
      </header>
      {
        isOpen &&
        <ModalMobileHeader
          isOpen={isOpen}
          keyword={keyword}
          setKeyword={setKeyword}
          handleKeyDown={handleKeyDown}
          setIsOpen={setIsOpen}
        />
      }
    </>
  )
}

const ModalMobileHeader = ({
  isOpen,
  setIsOpen,
  keyword,
  setKeyword,
  handleKeyDown,
}) => {
  const navigate = useNavigate()
  const [isOpenCategory, seIsOpenCategory] = useState(false)
  const productType = useSelector((state) => state.productType);
  const [subCategories, setSubCategories] = useState([]);
  const handleClickMenu = (path) => {
    navigate(path)
    setIsOpen(false)
  }
  const onProductTypeClicked = (data) => {
    setSubCategories(data.product_tags);

    if (!data.product_tags.length) {
      navigate(`/schedule-catalogue?product_type_id=${encrypt(data.id)}`);
      setIsOpen(false)
    }
  };

  const onSubCategoryClicked = (data) => {
    navigate(
      `/schedule-catalogue?product_type_id=${encrypt(
        data.product_type_id
      )}&product_tag_ids=${encrypt(data.id)}`
    );
    setIsOpen(false)
  };
  useEffect(() => {
    if(isOpen === false) setSubCategories([]);
  }, [isOpen]);

  return (
    <div className="fixed inset-0 z-50 h-screen w-screen bg-white">
      <div className="p-4 border-b border-Base-Outline">
        <button type="button" onClick={() => setIsOpen(false)}>
          <XIcon />
        </button>
      </div>
      <div className="px-4 pb-4 pt-2 border-b space-y-4 border-Base-Outline">
        <div className="w-full h-10 rounded-full px-4 py-3 border border-color-neutral-200 flex items-center space-x-[10px]">
          <SearchLineIcon />
          <input
            type="text"
            placeholder="Search Car"
            className="w-full border-transparent ring-0 focus:border-transparent focus:ring-0 focus:outline-none"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div>
          <div className="flex items-center justify-between text-color-neutral-500" onClick={() => seIsOpenCategory(prev => !prev)}>
            <div className="flex items-center space-x-1">
              <ApplicationIcon />
              <Typography
                text="Kategori"
                size="lg"
                color="secondary"
              />
            </div>
            <ArrowDropdownFillIcon />
          </div>
          {
            isOpenCategory &&
            <div className="grid grid-cols-12">
              <div className="col-span-5 pr-5">
                {
                  productType.map((type, index) => (
                    <button
                      key={`header-catetgory-${index + 1}`}
                      className="py-2 px-4 hover:bg-color-neutral-100 rounded-md w-full text-left"
                      onClick={() => onProductTypeClicked(type)}
                    >
                      <Typography
                        text={type.name}
                        size="sm"
                        weight="semibold"
                        color="secondary"
                      />
                    </button>
                  ))
                }
              </div>
              {subCategories.length ? (
                <div className="h-[100px] col-span-7 overflow-y-auto border-l border-color-neutral-200 px-2">
                  {
                    subCategories.map((sub, idx) => (
                      <button
                        key={`header-sub-catetgory-${idx + 1}`}
                        className="py-2 px-4 hover:bg-color-neutral-100 rounded-md w-full text-left"
                        onClick={() => onSubCategoryClicked(sub)}
                      >
                        <Typography
                          text={sub.tag_name}
                          size="sm"
                          color="base"
                        />
                      </button>
                    ))
                  }
                </div>
              ) : (
                ""
              )}
            </div>
          }
        </div>
      </div>
      <div className="flex flex-col p-4">
        {
          bottomList.map((item, index) => (
            <button
              key={`navling-header-${index + 1}`}
              onClick={() => handleClickMenu(item.path)}
              className="text-color-neutral-500 py-3 text-base text-left"
            >
              {item.label}
            </button>
          ))
        }
        <button
          type="button"
          onClick={() => handleClickMenu('/premium-auction')}
          className="text-left py-3"
        >
          <img src={PremiumUserHeader} alt="header" className="w-[115px]" />
        </button>
        <button className="flex items-center space-x-1 py-3">
          <MobileIcon />
          <Typography text="Download Aplikasi" color="base" weight="semibold" size="base" />
        </button>
      </div>
      <div>
      </div>
    </div>
  )
}

export default Header;

const bottomList = [
  {
    id: 1,
    label: 'Katalog Lelang',
    path: '/schedule-catalogue'
  },
  {
    id: 2,
    label: 'Jadwal Lelang',
    path: '/auction-schedule'
  },
  {
    id: 3,
    label: 'Titip Lelang',
    path: '/consignment-auction'
  },
  {
    id: 4,
    label: 'Beli NIPL',
    path: '/buy-nipl'
  },
  // {
  //   id: 5,
  //   label: PremiumUserIcon,
  //   path: '/premium-auction'
  // }
]