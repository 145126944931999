import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AuthRoute = ({ children }) => {
  const protectedPath = [
    '/login',
    '/register',
    '/forgot-password',
    '/reset-password'
  ]
  const { pathname } = useLocation();
  const isAuth = useSelector(state => state.logged)

  if (isAuth && protectedPath.find((path) => path === pathname)) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default AuthRoute;
