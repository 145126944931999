export const ColorGoogleIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8333 10.1584C18.8333 9.44664 18.7756 8.92727 18.6506 8.38867H10.5233V11.601H15.2939C15.1977 12.3994 14.6783 13.6016 13.5242 14.4095L13.508 14.517L16.0777 16.5077L16.2557 16.5255C17.8907 15.0154 18.8333 12.7937 18.8333 10.1584Z" fill="#4285F4" />
      <path d="M10.5234 18.622C12.8605 18.622 14.8225 17.8525 16.2557 16.5253L13.5241 14.4092C12.7932 14.919 11.8121 15.2749 10.5234 15.2749C8.23428 15.2749 6.29146 13.7649 5.59889 11.6777L5.49738 11.6864L2.82539 13.7543L2.79045 13.8514C4.2139 16.6791 7.13778 18.622 10.5234 18.622Z" fill="#34A853" />
      <path d="M5.59889 11.6779C5.41615 11.1393 5.31039 10.5622 5.31039 9.96591C5.31039 9.36956 5.41615 8.7925 5.58927 8.2539L5.58443 8.13919L2.87895 6.03809L2.79044 6.08019C2.20376 7.25361 1.86713 8.5713 1.86713 9.96591C1.86713 11.3605 2.20376 12.6782 2.79044 13.8516L5.59889 11.6779Z" fill="#FBBC05" />
      <path d="M10.5234 4.65661C12.1488 4.65661 13.2453 5.35872 13.8705 5.94546L16.3134 3.56018C14.8131 2.16558 12.8606 1.30957 10.5234 1.30957C7.13781 1.30957 4.21391 3.25239 2.79045 6.08006L5.5893 8.25376C6.29148 6.16666 8.23431 4.65661 10.5234 4.65661Z" fill="#EB4335" />
    </svg>

  )
}