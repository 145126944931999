export const CloseLineIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_915_88372)">
        <path d="M9.99999 8.82129L14.125 4.69629L15.3033 5.87462L11.1783 9.99962L15.3033 14.1246L14.125 15.303L9.99999 11.178L5.87499 15.303L4.69666 14.1246L8.82166 9.99962L4.69666 5.87462L5.87499 4.69629L9.99999 8.82129Z" fill="#DF2935" />
      </g>
      <defs>
        <clipPath id="clip0_915_88372">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}